import React, { FC } from "react"
import { Link } from "gatsby"

import { useWindowSize } from "./hooks"
import { WINDOW_MIN_WIDTH } from "./utils"
import { Helmet } from "react-helmet"

export interface BlogPostData {
  slug: string
  title: string
  createdAt: string
  heroImage: {
    file: {
      url: string
    }
    title: string
  }
  summary: {
    summary: string
  }
  tags: string[]
  blogContent?: {
    raw: string
    references: any
  }
}

export interface BlogPostPageContext {
  slug: string
  next: BlogPostData
  previous: BlogPostData
  continueReadingPosts: Array<BlogPostData>
}

export const PreviousArrow = () => (
  <svg
    width="13"
    height="20"
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.51 1.87013L10.73 0.100128L0.839966 10.0001L10.74 19.9001L12.51 18.1301L4.37997 10.0001L12.51 1.87013Z"
      fill="#979797"
    />
  </svg>
)

const NextArrow = () => (
  <svg
    width="13"
    height="20"
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.490004 18.1301L2.27 19.9001L12.16 10.0001L2.26001 0.100116L0.490005 1.87012L8.62 10.0001L0.490004 18.1301Z"
      fill="var(--primary-color)"
    />
  </svg>
)

export const readingTime = (blogContent: string) => {
  const text = blogContent //blog.blogContent.raw
  const wpm = 225
  const words = text.trim().split(/\s+/).length
  const time = Math.ceil(words / wpm)
  return time
}

export const PreviousNextSection: FC<{
  previous: BlogPostData
  next: BlogPostData
}> = ({ previous, next }) => {
  return (
    <section
      id="blog-previous-next-section"
      className="section-padding-left section-padding-right mb-5"
    >
      <div className="row pt-5">
        <div className="col-6">
          <Link to={`/blog/${previous.slug}/`} style={{ color: "#979797" }}>
            <PreviousArrow />
            Previous
          </Link>
        </div>
        <div className="col-6">
          <Link
            to={`/blog/${next.slug}/`}
            style={{ justifyContent: "flex-end" }}
          >
            Next
            <NextArrow />
          </Link>
        </div>
      </div>
    </section>
  )
}

export const BlogItem: FC<{
  blog: BlogPostData
  theme: "light" | "dark"
}> = props => {
  const { blog, theme } = props
  const rootClass = {
    light: "bg-light post-light",
    dark: "bg-dark-02 post-dark",
  }[theme]

  const limitDescription = text => {
    return text.length > 115 ? `${text.substring(0, 114)}...` : text
  }

  const postDetailsURL = `/blog/${blog.slug}/`

  const goToBlog = () => {
    window.open(postDetailsURL, "_blank");
  }

  return (
    <div
      className="col-md-6 col-lg-4"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
      </Helmet>
      <div className="blog-card" onClick={goToBlog}>
        <a href={postDetailsURL} target={"_blank"} onClick={event => event.stopPropagation()}>
          <div
            style={{
              width: "100%",
              height: 220,
              borderBottom: "2px solid #000000",
            }}
          >
            <img
              className="blog-card-image"
              src={blog.heroImage.file.url}
              alt=""
            />
          </div>
          <div
            className={`blog-posts-content w-100 ${rootClass}`}
            style={{ flex: 1 }}
          >
            <div className={`blog-date-card`}>
              {blog.createdAt} | {readingTime(blog.blogContent.raw)} Mins read
            </div>
            <div className={`blog-title-card`}>{blog.title}</div>
            <div className={`blog-description-card`}>
              {limitDescription(blog.summary.summary)}
            </div>
            <div
              className="blog-card-button"
              style={{
                marginBottom: "0",
              }}
            >
              READ MORE
              <img
                className="blog-card-button-icon"
                src={"/img/arrow-right.png"}
                alt="arrow"
                style={{
                  width: "9%",
                  marginTop: "-1%",
                  marginLeft: "3%",
                }}
              />
              <img
                className="blog-card-button-icon-selected web-view-new-design-display"
                src={"/img/arrow-right.png"}
                alt="arrow"
                style={{
                  width: "9%",
                  marginTop: "-1%",
                  marginLeft: "3%",
                }}
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export const BlogContinueReadingItem: FC<{
  blog: BlogPostData
  theme: "light" | "dark"
  colClassNames: string
}> = props => {
  const { blog, theme, colClassNames } = props
  const rootClass = {
    light: "bg-white post-light",
    dark: "bg-dark-02 post-dark",
  }[theme]
  const postDetailsURL = `/blog/${blog.slug}/`

  const goToBlog = () => {
    window.open(postDetailsURL, "_self")
  }

  return (
    <div
      className={colClassNames}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="blog-continue-reading-card" onClick={goToBlog}>
        <a href={postDetailsURL}>
          <div
            style={{
              width: "100%",
              height: 220,
            }}
          >
            <img
              className="blog-continue-reading-card-image"
              src={blog.heroImage.file.url}
              alt=""
            />
          </div>
          <div
            className={`blog-continue-reading-content w-100 ${rootClass}`}
            style={{ flex: 1 }}
          >
            <div className={`blog-continue-reading-date-card`}>
              {blog.createdAt} | {readingTime(blog.blogContent.raw)} Mins read
            </div>
            <div className={`blog-continue-reading-title-card`}>
              {blog.title}
            </div>
            <div
              className="blog-continue-reading-card-button"
              style={{
                marginTop: "5%",
              }}
            >
              READ MORE
              <img
                className="blog-card-button-icon"
                src={"/img/arrow-right.png"}
                alt="arrow"
                style={{
                  width: "6%",
                  marginTop: "-2%",
                  marginLeft: "3%",
                }}
              />
              <img
                className="blog-card-button-icon-selected web-view-new-design-display"
                src={"/img/arrow-right.png"}
                alt="arrow"
                style={{
                  width: "6%",
                  marginTop: "-2%",
                  marginLeft: "3%",
                }}
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export const ContinueReadingSection: FC<{
  continueReadingPosts: Array<BlogPostData>
}> = ({ continueReadingPosts }) => {
  const WebRow = () => (
    <div className="row">
      {continueReadingPosts.map(value => (
        <BlogItem blog={value} theme="light" />
      ))}
    </div>
  )
  const MobileRow = () => (
    <div className="row flex-nowrap" style={{ overflowX: "auto" }}>
      {continueReadingPosts.map(value => (
        <BlogContinueReadingItem
          blog={value}
          theme="light"
          colClassNames={`col-9`}
        />
      ))}
    </div>
  )
  const size = useWindowSize()
  return (
    <section className="section-padding-left section-padding-right">
      <div className="row pt-4" style={{ marginBottom: "8%" }}>
        <div className="col-12 blog-continue-reading-title">
          Continue Reading
        </div>
      </div>
      {size.width >= WINDOW_MIN_WIDTH ? <WebRow /> : <MobileRow />}
    </section>
  )
}

export const MAIN_ID_BLOG =
  "so-which-llms-are-the-best-for-building-a-customer-support-chatbot"
